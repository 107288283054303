import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin(){
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao(){
    const currentUser = authenticationService.currentUserValue;
     if (!currentUser) {             

        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
        PushToLogin();
       
    }
     
    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if(expiration < date){
        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
         PushToLogin();
    }
}

export default class AccountService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async GetClientes(value) {
        await checkSessao()
        const query = encodeURI(value);
        let resource = "Simulador/clientes" + "?busca=" + query;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                return result.data;
            })
            .catch(function (err) {

                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

    async GetTermoMaisRecente() {

        let resource = "Account/termo";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao recuperar termo de aceite!");
                }
                return result.data;
            })
            .catch(function (err) {

                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao recuperar termo de aceite!");
                return err.data;
            });

        return response;
    }

    async GetEstados() {
        await checkSessao()
        await checkSessao();
        let resource = "Simulador/Estados";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

    async GetCidadesEstado(uf) {
        await checkSessao()
        await checkSessao();
        let resource = "Simulador/Estados/" + uf + "/cidades";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

    async GetAlerts(empresaId) {
        await checkSessao()
        await checkSessao();
        let resource = "Notificacao/Avisos/" + empresaId;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }

                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");

                return err.data;
            });

        return response;
    }

    async GetNotifications(id) {
        await checkSessao()
        await checkSessao();
        let resource = "Notificacao/" + id;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }

                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");

                return err.data;
            });

        return response;
    }

    async VisualizaNotificacao(notificacaoId) {
        await checkSessao()
        await checkSessao();
        let resource = "Notificacao/Visualizar/" + parseInt(notificacaoId);
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }

                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");

                return err.data;
            });

        return response;
    }

};