import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class FrotaService {
    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async GetFrota(empresaAtiva, categoriaId, quantidade, skip, ordenacao, search = '') {
        await checkSessao();
        let resource = "Frota/" + empresaAtiva + "/" + categoriaId + "/" + quantidade + "/" + skip + "/" + ordenacao;

        resource += search.length > 0 ? `?search=${encodeURI(search)}` : ''

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async ChangeConsumo(veiculoId, value) {
        await checkSessao();
        let resource = `Frota/SetConsumo/${veiculoId}?value=${value}`;

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'PATCH',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async AtualizarPermissao(veiculoId, empresaId, valor) {
        await checkSessao();
        let resource = `Frota/atualizar-permissao`;

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({empresaId: empresaId, ativo: valor, frotaId: veiculoId }),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async AtualizarPermissaoEmpresa(empresaId, valor) {
        await checkSessao();
        let resource = `Frota/atualizar-permissao-empresa`;

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({empresaId: empresaId, ativo: valor }),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }


    async GetEmpresaAtiva(empresaId) {
        await checkSessao();
        let resource = `frota-162/possui-ativacao/${empresaId}`;

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async GetModal(empresaAtiva, placa) {
        await checkSessao();
        let resource = `frota-162/obter-info-modal/${empresaAtiva}/${placa}`;
        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }
};