import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';
import axios from 'axios';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class UploadService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getTomadores(empresasId, search) {
        await checkSessao();
        if (!(search?.length > 0))
            return [];
            
        let resource = "DocumentoFiscal/Tomadores";
        resource += `?search=${encodeURI(search)}`
        let url = this.urlBase + resource;
    
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
    
                return result.data;
            })
            .catch(function (err) {
    
                return err.data;
            });
    
        return response;

    }

    async getPlacas(empresasId, search) {
        await checkSessao();
        if (!(search?.length > 0))
            return [];

        let resource = "DocumentoFiscal/Placas";
        resource += `?search=${encodeURI(search)}`
        let url = this.urlBase + resource;
    
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
    
                return result.data;
            })
            .catch(function (err) {
    
                return err.data;
            });
    
        return response;

    }

    async getEmitentes(empresasId, search) {
        await checkSessao();
        if (!(search?.length > 0))
            return [];
            
        let resource = "DocumentoFiscal/Emitentes";
        resource += `?search=${encodeURI(search)}`
        let url = this.urlBase + resource;
    
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
    
                return result.data;
            })
            .catch(function (err) {
    
                return err.data;
            });
    
        return response;

    }

    async getDestinatarios(empresasId, search) {
        await checkSessao();
        if (!(search?.length > 0))
            return [];
            
        let resource = "DocumentoFiscal/Destinatarios";
        resource += `?search=${encodeURI(search)}`
        let url = this.urlBase + resource;
    
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
    
                return result.data;
            })
            .catch(function (err) {
    
                return err.data;
            });
    
        return response;
    }

    async uploadArquivos(empresaId, data) {
        await checkSessao();
        
        let resource = "Empresa/" + empresaId + "/UploadArquivos";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetDocumentos(empresaId, categoriaId, search, quantidade, skip, ordenacao, filtro = null) {

        //alert(categoriaId);

        await checkSessao();
        let resource = "DocumentoFiscal/";
        switch (categoriaId) {
            case 1:
                resource += "cte";
                break;
            case 2:
                resource += "mdfe";
                break;
            case 3:
                resource += "nfe";
                break;
            default:
                resource += "nfse";
                break;
        }
        let properties = "?empresaId=" + empresaId + "&quantidade=" + quantidade + "&skip=" + skip + "&ordenacao=" + ordenacao + "&search=" + encodeURI(search);
        let url = this.urlBase + resource + properties;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(filtro),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async uploadCertificado(empresaId, data) {
        let resource = "Empresa/" + empresaId + "/CadastrarCertificado";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            data: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetLink(empresaId, tipoDoc) {
        await checkSessao();
        let resource = `DocumentoFiscal/link-arquivos/${empresaId}/${tipoDoc}`;        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetExcel(empresaId, categoriaId, filtro = null) {

        console.log(filtro);

        await checkSessao();
        let resource = "DocumentoFiscal/gerar-excel/";
        switch (categoriaId) {
            case 2:
                resource += "mdfe/";
                break;
            case 3:
                resource += "nfe/";
                break;
            default:
                resource += "cte/";
                break;
        }
        let url = this.urlBase + resource + empresaId;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(filtro),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetDadosInfoModal(empresa, tipoDoc, chave) {
        console.log(chave);

        await checkSessao();
        let resource = `DocumentoFiscal/info-detalhes-modal/${empresa}/tipo/${tipoDoc}/chave/${chave}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetFila(empresaId, qtde, skip, erro) {
        await checkSessao();
        let resource = `DocumentoFiscal/fila-documentos/${empresaId}/${qtde}/${skip}/${erro}`;
        
        let url = this.urlBase + resource

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetFilaCards(empresaId,erro) {
        await checkSessao();
        let resource = `DocumentoFiscal/fila-documentos-cards/${empresaId}/${erro}`;
        
        let url = this.urlBase + resource

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetFilaExcel(empresaId) {
        await checkSessao();
        let resource = `DocumentoFiscal/fila-documentos-excel/${empresaId}`;
        
        let url = this.urlBase + resource

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }
};