import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class DespesaService {

    //@collaps

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async GetDespesas(empresaId, quantidade, skip, ordenacao, search = null) {
        await checkSessao();

        let resource = `Despesas/${empresaId}`
        let filter = `?quantidade=${quantidade}&skip=${skip}&ordenacao=${ordenacao}`;

        if (search != null)
            filter += `&search=${encodeURI(search)}`

        let url = this.urlBase + resource + filter;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async GetQuantidadeDespesas(empresaId) {
        await checkSessao();

        let resource = `Despesas/${empresaId}/Quantidade`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async GetDespesaById(despesaId) {
        await checkSessao();

        let resource = `Despesas/Despesa/${despesaId}`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async NewDespesa(data) {
        await checkSessao();


        let resource = `Despesas/New`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async InativarDespesa(despesaId) {
        await checkSessao();

        let resource = `Despesas/${despesaId}/Inativar`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'PATCH',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async ReativarDespesa(despesaId) {
        await checkSessao();

        let resource = `Despesas/${despesaId}/Reativar`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'PATCH',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async EditarDespesa(despesaId, newData) {
        await checkSessao();

        let resource = `Despesas/${despesaId}/Editar`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(newData),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async GetTiposDespesa(empresaId, search = undefined) {
        await checkSessao();

        let resource = `Despesas/TiposDespesa/${empresaId}`

        if(search?.length > 0)
            resource += `?search=${encodeURI(search)}`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async GetTipoDespesaById(tipoId) {
        await checkSessao();

        let resource = `Despesas/TiposDespesa/Get/${tipoId}`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async NewTipoDespesa(data, empresaId) {
        await checkSessao();

        let resource = `Despesas/TiposDespesa/${empresaId}/New`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }
};