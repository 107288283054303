import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

const permissaoInsight = "InsightPermissao";
const grupos = "grupo-acesso";
const grupoInsight = "GrupoInsight";
const usuarioInsight = "UsuarioInsight";
const usuarioGrupo = "UsuarioGrupo";

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class InsightsPermissaoService {
    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async CadastrarIntegracao(dados) {
        await checkSessao();
        let resource = `Integracao/add`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dados),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async ObterArquivei(empresaId) {
        await checkSessao();
        let resource = `Integracao/obter-por-empresa-id/${empresaId}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }
};