import AuthHeaderService from './AuthHeaderService';
import AuthService from './AuthService';
import EmpresaService from './EmpresaService';
import HomeService from './HomeService';
import InsightsService from './InsightsService';
import BenchmarkService from './BenchmarkService';
import AnaliseInsumosService from './AnaliseInsumosService';
import AnaliseClientesService from './AnaliseClientesService';
import AnaliseCargaService from './AnaliseCargaService';
import PerfilService from './PerfilService';
import DataScoreService from './DataScoreService';
import AccountService from './AccountService';
import SimuladorService from './SimuladorService';
import FaturamentoService from './FaturamentoService';
import OnboardingService from './OnboardingService';
import OnDemandService from './OnDemandService';
import UsuarioService from './UsuarioService';
import UploadService from './UploadService';
import MetasService from './MetasService';
import InteligenciaService from './InteligenciaService';
import PlanosService from './PlanosService';
import DespesaService from './DespesaService';
import ReceitaService from './ReceitaService';
import FrotaService from './FrotaService';
import InsightsPermissaoService from './InsightsPermissaoService';
import TelasPermissaoService from './TelasPermissaoService';
import ChatService from './ChatService';
import IntegracaoService from './IntegracaoService';
import AnaliseCarbonoService from './AnaliseCarbonoService';
import EmbarcadorService from './EmbarcadorService';
import AnaliseGestaoTranspServices from './AnaliseGestaoTranspServices';
import ReleasesService from './ReleasesService';
import GestaoFreteService from './GestaoFreteService';
import RegrasService from './RegrasService';
import LLMService from './LLMService';
import MalhaService from './MalhaService';
import LastMileService from './LastMileService';


const urlBase = process.env.REACT_APP_URL_BASE;
//console.log(urlBase);

export default class Services {

    static get AuthHeader() {
        return new AuthHeaderService();
    }

    static get Auth() {
        return new AuthService({ urlBase: urlBase });
    }
    static get Empresa() {
        return new EmpresaService({ urlBase: urlBase });
    }
    static get Home() {
        return new HomeService({ urlBase: urlBase });
    }
    static get Insights() {
        return new InsightsService({ urlBase: urlBase });
    }
    static get Benchmark() {
        return new BenchmarkService({ urlBase: urlBase });
    }
    static get AnaliseInsumos() {
        return new AnaliseInsumosService({ urlBase: urlBase });
    }
    static get AnaliseClientes() {
        return new AnaliseClientesService({ urlBase: urlBase });
    }
    static get AnaliseCarga() {
        return new AnaliseCargaService({ urlBase: urlBase });
    }
    static get Perfil() {
        return new PerfilService({ urlBase: urlBase });
    }
    static get DataScoreService() {
        return new DataScoreService({ urlBase: urlBase });
    }
    static get AccountService() {
        return new AccountService({ urlBase: urlBase });
    }
    static get Simulador() {
        return new SimuladorService({ urlBase: urlBase });
    }
    static get Faturamento() {
        return new FaturamentoService({ urlBase: urlBase });
    }
    static get Onboarding() {
        return new OnboardingService({ urlBase: urlBase });
    }
    static get OnDemand() {
        return new OnDemandService({ urlBase: urlBase });
    }
    static get Usuario() {
        return new UsuarioService({ urlBase: urlBase });
    }
    static get Upload() {
        return new UploadService({ urlBase: urlBase });
    }
    static get Metas() {
        return new MetasService({ urlBase: urlBase });
    }
    static get InteligenciaComercial() {
        return new InteligenciaService({ urlBase: urlBase });
    }
    static get Planos() {
        return new PlanosService({ urlBase: urlBase });
    }
    static get Despesas() {
        return new DespesaService({ urlBase: urlBase });
    }
    static get Receitas() {
        return new ReceitaService({ urlBase: urlBase });
    }
    static get Frota() {
        return new FrotaService({ urlBase: urlBase });
    }
    static get PermissaoInsight() {
        return new InsightsPermissaoService({ urlBase: urlBase });
    }
    static get PermissaoTelas() {
        return new TelasPermissaoService({ urlBase: urlBase });
    }
    static get ChatServices() {
        return new ChatService({ urlBase: urlBase });
    }
    static get IntegracaoServices() {
        return new IntegracaoService({ urlBase: urlBase });
    }
    static get AnaliseCarbono() {
        return new AnaliseCarbonoService({ urlBase: urlBase });
    }
    static get Embarcador() {
        return new EmbarcadorService({ urlBase: urlBase });
    }
    static get GestaoTransportes() {
        return new AnaliseGestaoTranspServices({ urlBase: urlBase });
    }
    static get Releases() {
        return new ReleasesService({ urlBase: urlBase });
    }
    static get GestaoFrete() {
        return new GestaoFreteService({ urlBase: urlBase });
    }
    static get Regras() {
        return new RegrasService({ urlBase: urlBase });
    }
    static get LLM() {
        return new LLMService({ urlBase: urlBase });
    }
    static get Malha() {
        return new MalhaService({ urlBase: urlBase });
    }
    static get LastMile() {
        return new LastMileService({ urlBase: urlBase });
    }
}
