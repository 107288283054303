import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import Services from '../Services/DataOceanServices';
import authHeader from './AuthHeaderService';

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('user')));

export const authenticationService = {
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

export default class AuthService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async login(data) {
        
        let resource = "Account/login";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(handleResponse)
            .then(async function (result) {

                if (result.success) {
                    
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.warning(result.mensagem);
                    
                    sessionStorage.clear();
                    sessionStorage.setItem('user', JSON.stringify(result.data));
                    currentUserSubject.next(result.data);
                    await setEmpresaId(result.data.token)
                    await setEmpresaAtiva(result.data.token);
                    await setAdministradorConta(result.data.token);
                    
                    sessionStorage.setItem("prim_ac", 'true');
                }
                else {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error("" + result.mensagem, 10);
                    sessionStorage.clear();
                }
                return result;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error("Erro ao realizar login: " + err.message);
                err.success = false;
                sessionStorage.clear();
                return err;
            });

        return response;
    }


    async loginAuto(id) {
        
        let resource = "Account/login-auto/" + id;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: {},
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(handleResponse)
            .then(async function (result) {

                if (result.success) {
                    
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.warning(result.mensagem);
                    
                    sessionStorage.clear();
                    sessionStorage.setItem('user', JSON.stringify(result.data));
                    currentUserSubject.next(result.data);
                    await setEmpresaId(result.data.token)
                    await setEmpresaAtiva(result.data.token);
                    await setAdministradorConta(result.data.token);
                    
                    sessionStorage.setItem("prim_ac", 'true');
                }
                else {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result.mensagem);
                    sessionStorage.clear();
                }
                return result;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error("Erro ao realizar login: " + err.message);
                err.success = false;
                sessionStorage.clear();
                return err;
            });

        return response;
    }
    
    async innerLogin(data) {
        
        let resource = "Account/login";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(handleResponse)
            .then(async function (result) {
                return result;
            })
            .catch(function (err) {
                return err;
            });

        return response;
    }

    async logout() {
        sessionStorage.removeItem("user");
        currentUserSubject.next(null);
    }

    async getCurrentUser() {
        return JSON.parse(authenticationService.currentUserValue);
    }


    async register(data) {

        let resource = "Account";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(handleResponse)
            .then(async function (result) {

                if (result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.warning(result.mensagem);
                    sessionStorage.clear();
                    sessionStorage.setItem('user', JSON.stringify(result.data));
                    currentUserSubject.next(result.data);
                    await setEmpresaId(result.data.token)
                    await setEmpresaAtiva(result.data.token);
                    await setAdministradorConta(result.data.token);

                }
                else {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result.mensagem);
                    sessionStorage.clear();
                }
                return result;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error("Erro ao cadastrar usuário: " + err.message);
                err.success = false;
                sessionStorage.clear();
                return err;
            });

        return response;
    }


    async addFiliais(cnpj) {
        let resource = `Account/add-filiais-auto/${cnpj}`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

};

async function getAdministradorConta(token) {
    
    let user = DecodeTokenJwt(token);
    return user.administradorConta;
}

async function getPrimeiraEmpresa(token) {
    
    let user = DecodeTokenJwt(token);
    let empresaId = '';
    if(typeof user.empresas == 'string'){
        empresaId = parseInt(user.empresas)
    }
    else{
        empresaId = parseInt(user.empresas[0])
    }
    return empresaId;
}

async function setEmpresaId(token) {
    let empresaId = await getPrimeiraEmpresa(token);
    sessionStorage.setItem('empresaId', empresaId);
}

async function setEmpresaAtiva(token) {
    let empresaId = await getPrimeiraEmpresa(token);
    let empresaAtiva = await Services.Empresa.getEmpresaById(empresaId);
     
    sessionStorage.setItem('empresaAtiva', JSON.stringify(empresaAtiva));
}

async function setAdministradorConta(token) {
    let administradorConta = await getAdministradorConta(token);     
    sessionStorage.setItem('administradorConta', administradorConta);
}