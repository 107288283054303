import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}


export default class LastMileService {
    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }
    async Obter(empresaId, payload) {
        await checkSessao();

        let resource = `last-mile/obter/${empresaId}`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'Post',
            body: JSON.stringify(payload),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
        .then(handleResponse)
        .then(function (result) {
            return result.data;
        })
        .catch(function (err) {
            return err.data;
        });


        return response;
    }

    async AtualizarConsumos(id, payload) {
        await checkSessao();

        let resource = `last-mile/atualizar-consumos/${id}`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'Patch',
            body: JSON.stringify(payload),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
        .then(handleResponse)
        .then(function (result) {
            return result.data;
        })
        .catch(function (err) {
            return err.data;
        });


        return response;
    }

    async getTransportadora(empresaId, value) {
            
        const query = encodeURI(value);
        let resource = `last-mile/pesquisar-transportadora/${empresaId}/${query}`;
        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }
    async getFilial(empresaId, value) {
            
        const query = encodeURI(value);
        let resource = `last-mile/pesquisar-filial/${empresaId}/${query}`;
        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }
    async getMotorista(empresaId, value) {
            
        const query = encodeURI(value);
        let resource = `last-mile/pesquisar-motorista/${empresaId}/${query}`;
        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }
    async getPlacas(empresaId, value) {
            
        const query = encodeURI(value);
        let resource = `last-mile/pesquisar-placas/${empresaId}/${query}`;
        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }

    async ObterListagemEmpresas(empresaId, payload) {
        await checkSessao();

        let resource = `last-mile/permissao-obter-todos`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'Get',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
        .then(handleResponse)
        .then(function (result) {
            return result.data;
        })
        .catch(function (err) {
            return err.data;
        });


        return response;
    }

    async AtivarDesativar(empresa, ativo) 
    {
        await checkSessao();
        let resource = `last-mile/permissao-atualizar`;
        let url = this.urlBase + resource;

        let data = {
            usuarioId: empresa,
            ativo: ativo    
        }
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }
};