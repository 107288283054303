import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class MetasService {

    //@collaps

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getMetas(empresaId, quantidade, skip, ordenacao, search = '') {
        await checkSessao();

        let resource = `Metas/${empresaId}`
        let filter = `?quantidade=${quantidade}&skip=${skip}&ordenacao=${ordenacao}`;

        if (search.length > 0)
            filter += `&search=${encodeURI(search)}`

        let url = this.urlBase + resource + filter;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async getQuantidadeMetas(empresaId, usuarioId = null) {
        await checkSessao();

        let resource = `Metas/${empresaId}/Quantidade`

        if (usuarioId != null)
            resource += `&usuarioId=${usuarioId}`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });


        return response;
    }

    async getDadosGraficos(empresaId, dataInicio, dataFim) {
        await checkSessao();

        let data = {
            dataInicio: dataInicio,
            dataFim: dataFim,
        }

        let resource = `Metas/${empresaId}/Graficos`

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });


        return response;
    }

    async getMetaById(metaId) {
        await checkSessao();

        let resource = `Metas/Meta/${metaId}`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async newMeta(meta) {
        await checkSessao();

        let resource = `Metas/New`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(meta),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (result.success) {

                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.warning(result.mensagem);
                }
                else {

                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result.mensagem);
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err.mensagem);
                return err.data;
            });


        return response;
    }

    async inativarMeta(empresaId, metaId) {
        await checkSessao();

        let resource = `Metas/${empresaId}/Inativar`
        let filter = `?metaId=${metaId}`;
        let url = this.urlBase + resource + filter;

        var response = await fetch(url, {
            method: 'PATCH',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async reativarMeta(empresaId, metaId) {
        await checkSessao();

        let resource = `Metas/${empresaId}/Reativar`
        let filter = `?metaId=${metaId}`;
        let url = this.urlBase + resource + filter;

        var response = await fetch(url, {
            method: 'PATCH',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async editarMeta(empresaId, metaId, meta) {
        await checkSessao();

        let resource = `Metas/${empresaId}/Editar`
        let filter = `?metaId=${metaId}`;
        let url = this.urlBase + resource + filter;

        var response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(meta),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async getCategorias() {
        await checkSessao();

        let resource = `Metas/Categorias`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async getTiposCategoria(categoriaId) {
        await checkSessao();

        let resource = `Metas/TiposMeta/${categoriaId}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async getOpcoesVariavel(empresaId, tipoMetaId, search = '') {
        await checkSessao();

        let resource = `Metas/TiposMeta/Opcoes/${empresaId}/${tipoMetaId}?search=${encodeURI(search)}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }
};