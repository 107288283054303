import { handleResponse } from '../Assets/handleResponse/handleResponse';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';
import authHeader from './AuthHeaderService';

function PushToLogin(){
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao(){
    const currentUser = authenticationService.currentUserValue;
     if (!currentUser) {             

        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
        PushToLogin();
       
    }
     
    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if(expiration < date){
        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
         PushToLogin();
    }
}


export default class MalhaService {
    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async GetDados(dados, empresaId)
    {
        await checkSessao()

        let resource = `dash-malha/obter/${empresaId}`;
        let url = this.urlBase + resource;


        var response = await fetch(url, {
            method: 'Post',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() },
            body: JSON.stringify(dados),
        })
        .then(handleResponse)
        .then(function (result) {
            return result.data;
        })
        .catch(function (err) {
            return err.data;
        });
        return response;
    }

    async getMunicipios(value) {
        
        const query = encodeURI(value);
        let resource = `dash-malha/municipios?busca=${query}`;
        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }
};