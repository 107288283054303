import { handleResponse } from '../Assets/handleResponse/handleResponse'
import authHeader from './AuthHeaderService'
import { useHistory } from 'react-router-dom'
import { authenticationService } from './AuthService'

function PushToLogin() {
  const history = useHistory()
  history.push('/Login')
}

async function checkSessao() {
  const currentUser = authenticationService.currentUserValue
  if (!currentUser) {
    setTimeout(() => {
      window.location.reload()
    }, 500)
    //não está logado, redirecione para a página de login com o url de retorno

    PushToLogin()
  }

  var expiration = Date.parse(currentUser.expiration)
  var date = Date.parse(new Date())
  if (expiration < date) {
    setTimeout(() => {
      window.location.reload()
    }, 500)
    //não está logado, redirecione para a página de login com o url de retorno

    PushToLogin()
  }
}

export default class DataScoreService {
  constructor({ urlBase }) {
    this.urlBase = urlBase
  }

  async getDataScore(empresaId, page) {
    await checkSessao()
    let resource = 'DataScore/' + empresaId + '/' + page
    let url = this.urlBase + resource

    var response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authHeader()
      }
    })
      .then(handleResponse)
      .then(function (result) {
        return result.data
      })
      .catch(function (err) {
        return err.data
      })

    return response
  }

  async GetRelatorio(empresaId, tipoArquivo, critica, anoMes) {
    await checkSessao()
    let resource = 'DataScore/download'
    let url = this.urlBase + resource
    let data = {
      empresaId: empresaId,
      tipoArquivo: tipoArquivo,
      critica: critica,
      anoMes: anoMes
    }

    var response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authHeader()
      }
    })
      .then(handleResponse)
      .then(function (result) {
        return result.data
      })
      .catch(function (err) {
        return err.data
      })

    return response
  }

  async GetRelatorioXLS(empresaId, tipoArquivo, critica, anoMes) {
    await checkSessao()
    let resource = 'DataScore/download-excel'
    let url = this.urlBase + resource
    let data = {
      empresaId: empresaId,
      tipoArquivo: tipoArquivo,
      critica: critica,
      anoMes: anoMes
    }

    var response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authHeader()
      }
    })
      .then(handleResponse)
      .then(function (result) {
        return {base64: result.data, desc: result.mensagem}
      })
      .catch(function (err) {
        return err.data
      })

    return response
  }
  /*
    async GetRelatorioAll(empresaId) {
        await checkSessao();
        let resource = "DataScore/downloadAll/" + empresaId;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }
    */
}
