import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';
import axios from 'axios';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class OnboardingService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async uploadCertificate(empresaId, data) {
        await checkSessao();
        let resource = "Empresa/" + empresaId + "/CadastrarCertificado";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (result.data == undefined) {
                    return result;
                } else {
                    return result.data;
                }
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async uploadFiles(empresaId, formData) {
        await checkSessao();

        

        let resource = "Empresa/" + empresaId + "/UploadArquivos";
        let url = this.urlBase + resource;

        var response = await axios
            .post(url, formData, {
                onUploadProgress: (event) => {
                    
                    let progress = Math.round(
                        (event.loaded * 100) / event.total
                    );

                    console.log(
                        `O arquivo está ${progress}% carregado... `
                    );
                },
            })/*

        var response = await fetch(url, {
            method: 'POST',
            body: formdata,
            headers: { 'Authorization': authHeader() }
        })*/
            .then(function (result) {
                
                return result.data.data;
            })
            .catch(function (err) {
                
                return err.data;
            });

        return response;
    }
};