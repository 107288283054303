import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class InsightsService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getQuantidadeNovosInsights(empresaId) {
        await checkSessao();
        let resource = "Insights/" + empresaId + "/Novos";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async getUltimosInsights(empresaId, quantidade, skip = 0, categoriaId = 0, search = '') 
    {
        await checkSessao();
        let resource = "Insights/" + empresaId + "/" + quantidade + "/" + skip + "/" + categoriaId + "?search=" + search;
        if (search = ''){
            resource = "Insights/" + empresaId + "/" + quantidade + "/" + skip + "/" + categoriaId;
        }
        
        let url = this.urlBase + resource;
        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async getCategoriasInsights() {
        await checkSessao();

        let resource = "Insights/getCategorias";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response || [];
    }

    async getById(id) {

        await checkSessao();
        let resource = "Insights/" + id;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err.mensagem);
                return err.data;
            });

        return response;
    }

    async Arquivar(id) {

        await checkSessao();
        let resource = "Insights/arquivar/" + id;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'PATCH',
            body: {},
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err.mensagem);
                return err.data;
            });

        return response;
    }

    async SetVisualizado(insights) {

        await checkSessao();
        let resource = "Insights/Visualizar";
        let url = this.urlBase + resource;

        let data = {
            insights: insights
        }

        var response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async AvaliarInsight(insightId, avaliacao) {

        await checkSessao();
        let resource = "Insights/Avaliar";
        let url = this.urlBase + resource;

        let data = {
            insightId: insightId,
            avaliacao: avaliacao
        }

        var response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err.mensagem);
                return err.data;
            });

        return response;
    }

    async ComentarInsight(insightId, comentario) {

        await checkSessao();
        let resource = "Insights/Comentar";
        let url = this.urlBase + resource;

        let data = {
            insightId: insightId,
            comentario: comentario
        }

        var response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.warning('Comentário Salvo!');
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err.mensagem);
                return err.data;
            });

        return response;
    }
};