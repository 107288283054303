import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

const permissaoInsight = "InsightPermissao";
const grupos = "grupo-acesso";
const grupoInsight = "GrupoInsight";
const usuarioInsight = "UsuarioInsight";
const usuarioGrupo = "UsuarioGrupo";

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class InsightsPermissaoService {
    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async ObterInsights(search, empresaId, skip, quantidade) {
        await checkSessao();
        if (search != null && search != 'undefined' && search != "") {
            skip = 0;
            quantidade = 10;
            search = `?search=${search}`;
            //search = ``;
        }
        else {
            search = "";
        }
        let resource = `${permissaoInsight}/carga/${empresaId}/${skip}/${quantidade}${search}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async SetAtivar(insightId, empresaId) {
        await checkSessao();
        let resource = `${permissaoInsight}/ativar`;
        let url = this.urlBase + resource;

        let data = {
            EmpresaId: empresaId,
            InsightId: insightId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }


    async Contagem(empresaId) {
        await checkSessao();
        let resource = `${permissaoInsight}/contagem`;
        let url = this.urlBase + resource;

        let data = {
            EmpresaId: empresaId,
            InsightId: 0
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async SetInativar(insightId, empresaId) {
        await checkSessao();
        let resource = `${permissaoInsight}/inativar`;
        let url = this.urlBase + resource;

        let data = {
            EmpresaId: empresaId,
            InsightId: insightId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    //Grupos
    async ObterGrupos(empresaId, insight) {
        await checkSessao();
        let resource = `${grupos}/listar/${empresaId}/${insight}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async CadastrarGrupo(descricao, empresaId) {
        await checkSessao();
        let resource = `${grupos}/criar`;
        let url = this.urlBase + resource;

        let data = {
            Descricao: descricao,
            EmpresaId: empresaId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async SetAtivarGrupo(insightId, grupoId) {
        await checkSessao();
        let resource = `${grupoInsight}/ativar`;
        let url = this.urlBase + resource;

        let data = {
            GrupoId: grupoId,
            InsightId: insightId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async SetInativarGrupo(insightId, grupoId) {
        await checkSessao();
        let resource = `${grupoInsight}/inativar`;
        let url = this.urlBase + resource;

        let data = {
            GrupoId: grupoId,
            InsightId: insightId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    //Usuários
    async ObterUsuarios(empresaId, insight) {
        await checkSessao();
        let resource = `${usuarioInsight}/listar/${empresaId}/${insight}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async SetAtivarUsuario(insightId, usuarioId) {
        await checkSessao();
        let resource = `${usuarioInsight}/ativar`;
        let url = this.urlBase + resource;

        let data = {
            UsuarioId: usuarioId,
            InsightId: insightId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async SetInativarUsuario(insightId, usuarioId) {
        await checkSessao();
        let resource = `${usuarioInsight}/inativar`;
        let url = this.urlBase + resource;

        let data = {
            UsuarioId: usuarioId,
            InsightId: insightId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    //Usuarios - Grupos
    async ObterUsuarioGrupos(usuarioId) {
        await checkSessao();
        let resource = `${usuarioGrupo}/listar/${usuarioId}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }


    async SetAtivarUsuarioGrupo(grupoId, usuarioId) {
        await checkSessao();
        let resource = `${usuarioGrupo}/ativar`;
        let url = this.urlBase + resource;

        let data = {
            UsuarioId: usuarioId,
            GrupoId: grupoId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async SetInativarUsuarioGrupo(grupoId, usuarioId) {
        await checkSessao();
        let resource = `${usuarioGrupo}/inativar`;
        let url = this.urlBase + resource;

        let data = {
            UsuarioId: usuarioId,
            GrupoId: grupoId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    // async getById(id) {

    //     await checkSessao();
    //     let resource = "Insights/" + id;
    //     let url = this.urlBase + resource;

    //     var response = await fetch(url, {
    //         method: 'GET',
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
    //     })
    //         .then(handleResponse)
    //         .then(function (result) {

    //             return result.data;
    //         })
    //         .catch(function (err) {
    //             alertify.set('notifier', 'position', 'bottom-right');
    //             alertify.error(err.mensagem);
    //             return err.data;
    //         });

    //     return response;
    // }

    // async SetVisualizado(insights) {

    //     await checkSessao();
    //     let resource = "Insights/Visualizar";
    //     let url = this.urlBase + resource;

    //     let data = {
    //         insights: insights
    //     }

    //     var response = await fetch(url, {
    //         method: 'PATCH',
    //         body: JSON.stringify(data),
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
    //     })
    //         .then(handleResponse)
    //         .then(function (result) {
    //             return result.data;
    //         })
    //         .catch(function (err) {
    //             return err.data;
    //         });
    //     return response;
    // }

    // async AvaliarInsight(insightId, avaliacao) {

    //     await checkSessao();
    //     let resource = "Insights/Avaliar";
    //     let url = this.urlBase + resource;

    //     let data = {
    //         insightId: insightId,
    //         avaliacao: avaliacao
    //     }

    //     var response = await fetch(url, {
    //         method: 'PATCH',
    //         body: JSON.stringify(data),
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
    //     })
    //         .then(handleResponse)
    //         .then(function (result) {

    //             return result.data;
    //         })
    //         .catch(function (err) {
    //             alertify.set('notifier', 'position', 'bottom-right');
    //             alertify.error(err.mensagem);
    //             return err.data;
    //         });

    //     return response;
    // }

    // async ComentarInsight(insightId, comentario) {

    //     await checkSessao();
    //     let resource = "Insights/Comentar";
    //     let url = this.urlBase + resource;

    //     let data = {
    //         insightId: insightId,
    //         comentario: comentario
    //     }

    //     var response = await fetch(url, {
    //         method: 'PATCH',
    //         body: JSON.stringify(data),
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
    //     })
    //         .then(handleResponse)
    //         .then(function (result) {
    //             alertify.set('notifier', 'position', 'bottom-right');
    //             alertify.warning('Comentário Salvo!');
    //             return result.data;
    //         })
    //         .catch(function (err) {
    //             alertify.set('notifier', 'position', 'bottom-right');
    //             alertify.error(err.mensagem);
    //             return err.data;
    //         });

    //     return response;
    // }
};