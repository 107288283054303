import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class EmpresaService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getEmpresaById(empresaId) {
        await checkSessao();
        let resource = "Account/empresa/" + empresaId;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async getEmpresasById(empresas) {
        await checkSessao();
        let resource = "Account/empresas";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresas),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async getEmpresasUsuario() {

        let empresasId = [];
        let usuario = DecodeTokenJwt(JSON.parse(sessionStorage.getItem("user")).token);

        if (typeof usuario.empresas == 'string')
            empresasId = new Array(usuario.empresas);
        else
            empresasId = usuario.empresas;

        let empresas = [];
        for (const id of empresasId) {
            let empresa = await this.getEmpresaById(id);
            empresas.push(empresa);
        }
        return empresas;
    }

    async getEmpresasUsuarioApi(search, ordenacao, addReferente = false) {

        await checkSessao();
        let resource = "Empresa/listar-minhas-empresas?search=" + encodeURI(search) + "&ordenacao=" + ordenacao + "&addreferente=" + addReferente;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async mudaEmpresaAtiva(id) {

        try {
            sessionStorage.setItem('empresaId', id);
            let empresa = await this.getCurrentEmpresaById(id);
            sessionStorage.setItem('empresaAtiva', empresa);
            return true;
        }
        catch (err) {
            alertify.set('notifier', 'position', 'bottom-right');
            alertify.error("Erro ao mudar empresa: " + err.message);
            return false;
        }
    }

    async registerEmpresa(data) {
        await checkSessao();
        let resource = "Empresa/Cadastrar"
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async atualizarEmpresa(data) {
        await checkSessao();
        let resource = "Empresa/Atualizar"
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async deleteEmpresa(empresaId) {
        let resource = `Empresa/${empresaId}/Deletar`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async GetPlanoEmpresa(empresaId) {
        let resource = `Empresa/${empresaId}/Plano`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response?.data;
    }
};