import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';
import authHeader from './AuthHeaderService';

function PushToLogin(){
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao(){
    const currentUser = authenticationService.currentUserValue;
     if (!currentUser) {             

        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
        PushToLogin();
       
    }
     
    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if(expiration < date){
        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
         PushToLogin();
    }
}


export default class LLMService {
    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async GetDados()
    {
        await checkSessao()

        let resource = "llm/buscar-lista";
        let url = this.urlBase + resource;


        var response = await fetch(url, {
            method: 'Get',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
        .then(handleResponse)
        .then(function (result) {
            return result.data;
        })
        .catch(function (err) {
            return err.data;
        });
        return response;
    }

    async enviarPerguntaLista(pergunta, empresaId, usuarioId) {
        await checkSessao();
        let resource = `llm/perguntar-lista`;

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({pergunta: pergunta, empresaId: empresaId, usuarioId: usuarioId  }),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async enviarPerguntaLivre(pergunta, empresaId, usuarioId) {
        await checkSessao();
        let resource = `llm/perguntar-livre`;

        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({pergunta: pergunta, empresaId: empresaId, usuarioId: usuarioId }),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result;
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }
};