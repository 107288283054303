import { handleResponse } from '../Assets/handleResponse/handleResponse';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin(){
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao(){
    const currentUser = authenticationService.currentUserValue;
     if (!currentUser) {             

        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
        PushToLogin();
       
    }
     
    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if(expiration < date){
        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
         PushToLogin();
    }
}

export default class GestaoFreteService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getTabelasEmpresa(empresa) {
        let resource = `gestao-frete/obter-tabelas-empresa/${empresa}`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'Get',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }

    async getTabelas(tabelaId, page) {
        let resource = `gestao-frete/obter-tabela/${tabelaId}/${page}`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'Get',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }

    async getAuditorias(empresaId) {
        let resource = `gestao-frete/obter-lista-audit-frete/${empresaId}`
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'Get',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }

    async getAuditoriaCSV(empresaId, anoMes) {
        let resource = `dataocean-auditoria/auditoria-frete-download-csv/${empresaId}?anoMes=${anoMes}`;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'Get',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }
};