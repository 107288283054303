
import alertify from 'alertifyjs';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            data.success = false;
        }
        else {
            data.success = true
        }

        return data;
    });
}