import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin(){
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao(){
    const currentUser = authenticationService.currentUserValue;
     if (!currentUser) {             

        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
        PushToLogin();
       
    }
     
    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if(expiration < date){
        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
         PushToLogin();
    }
}

export default class FaturamentoService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getFaturamento(empresaId, filtro) {
        let resource = `faturamento/ObterFaturamento/${empresaId}`
        let url = this.urlBase + resource;

        let data = {
            periodoInicial: filtro.periodoInicial,
            periodoFinal: filtro.periodoFinal,
            estabelecimento: filtro.estabelecimento || [],
            cliente: filtro.clientes || [],
            municipioOrigem: filtro.municipioOrigem || [],
            municipioDestino: filtro.municipioDestino || [],
            veiculos: filtro.veiculos || [],
            servico: filtro.servico || [],
            frota: filtro.frota || [],
            tipoCarga: filtro.tipoCarga || [],
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });


        return response;
    }

    async getMunicipios(empresaId, value) {
        
        const query = encodeURI(value);
        let resource = `faturamento/municipios/${empresaId}?busca=${query}`;
        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }

    async getMunicipiosDestino(empresaId, value) {
        
        const query = encodeURI(value);
        let resource = `faturamento/municipios-destino/${empresaId}?busca=${query}`;
        
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                return result.data;
            })
            .catch(function (err) {
                return err.data;
            });
        return response;
    }
};