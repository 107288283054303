import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';

export default class ReleasesService {
    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }
    async GetDados()
    {
        let resource = "Release/obter";
        let url = this.urlBase + resource;


        var response = await fetch(url, {
            method: 'POST',
            body: {},
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao recuperar releases");
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao recuperar releases!");
                return err.data;
            });

        return response;
    }
};