import React from 'react';
import { Spinner } from 'reactstrap';
import './PageLoading.css';

// https://www.davidhu.io/react-spinners/

const LoadingPage = props => {

    const id = props.id;
    const style = props.style;
    const hidden = props.hidden || false;
    const color = props.color === undefined ? "var(--Color-Grayscale_200)" : props.color;
    const size = props.size === undefined ? 20 : props.size;
    const className = props.className === undefined ? "spinnerCenter" : props.className;

    return (
        <div id={id} hidden={hidden} className='classLoaderCenter' style={style}>
            <Spinner className={className} style={{ color }} />
        </div>
    );
}

export default LoadingPage;