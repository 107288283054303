// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.classLoaderCenter{
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
}
.spinnerCenter{
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
}`, "",{"version":3,"sources":["webpack://./src/Assets/PageLoading/PageLoading.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".classLoaderCenter{\n    width: 100%;\n    height: 100%;\n    align-items: center;\n    text-align: center;\n}\n.spinnerCenter{\n    position: relative;\n    margin-top: auto;\n    margin-bottom: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
