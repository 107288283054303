// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*variable.css*/
* {
    --color-focus: var(--ColorC1);
    --colot-gray-600: #8F9BB3;
    --page-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Theme/variable.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":["/*variable.css*/\n* {\n    --color-focus: var(--ColorC1);\n    --colot-gray-600: #8F9BB3;\n    --page-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
