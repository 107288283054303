import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';
import axios from 'axios';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class OnDemandService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async Criar(payload) {
        await checkSessao();
        let resource = "on-demand/criar";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (result.data == undefined) {
                    return result.sucesso;
                } else {
                    return result;
                }
            })
            .catch(function (err) {
                return err.data;
            });

        return response;
    }

    async BuscarSobre() {
        await checkSessao();
        let url = this.urlBase + "on-demand/sobre/buscar";

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });
        return response;
    }

    async BuscarMetrica(sobreId) {
        await checkSessao();
        let url = this.urlBase + `on-demand/metrica/buscar/${sobreId}`;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });
        return response;
    }
};