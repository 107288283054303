import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useAuth } from '../hooks/useAuth'
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin(){
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao(){
    const currentUser = authenticationService.currentUserValue;
     if (!currentUser) {             

        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
        PushToLogin();
       
    }
     
    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if(expiration < date){
        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
         PushToLogin();
    }
}

async function GetEmpresaId(){
    const { empresaAtiva } = useAuth();
    return empresaAtiva;
}

export default class AnaliseCargaService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async GetDados(filtro, empresaId) {
        await checkSessao();
        let resource = "AnaliseCarga/Dados";
        let url = this.urlBase + resource;
        
        //TODO: resolver erro de filtros na página de análise de carga (cliente e veiculo)
        
        let data = {
            periodoInicial: filtro.periodoInicial,
            periodoFinal: filtro.periodoFinal,
            estabelecimento: filtro.estabelecimento || [],
            cliente: filtro.clientes || [],
            tipoCarga: filtro.tipoCarga || [],
            veiculos: filtro.veiculos || [],
            tipoVeiculo: filtro.tipoVeiculo || [],
            frota: filtro.frota || [],
            empresa: empresaId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

    async GetMercado(filtro) {

        await checkSessao();
        let resource = "AnaliseCarga/Mercado";
        let url = this.urlBase + resource;
        
        //TODO: resolver erro de filtros na página de análise de carga (cliente e veiculo)

        let data = {
            periodoInicial: filtro.periodoInicial,
            periodoFinal: filtro.periodoFinal,
            estabelecimento: filtro.estabelecimento,
            cliente: filtro.clientes,
            tipoCarga: filtro.tipoCarga,
            veiculos: filtro.veiculos,
            tipoVeiculo: filtro.tipoVeiculo,
            frota: filtro.frota
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                
                return err.data;
            });

        return response;
    }

    async GetVeiculos(empresaId) {
        await checkSessao();
        let resource = "Faturamento/veiculos/"+ empresaId;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

    async GetVeiculos(empresaId, value) {

        const query = encodeURI(value);
        let resource = `Faturamento/veiculos/${empresaId}?busca=${query}`;
        let url = this.urlBase + resource;
    

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }
};