import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin(){
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao(){
    const currentUser = authenticationService.currentUserValue;
     if (!currentUser) {             

        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
        PushToLogin();
       
    }
     
    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if(expiration < date){
        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
         PushToLogin();
    }
}

export default class AnaliseClientesService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getDadosGraficos(empresaAtiva, raiz) {

        await checkSessao();
        let resource = "AnaliseClientes/" + empresaAtiva + "/Analise/" + raiz;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result.mensagem);
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err.mensagem);
                return err.data;
            });

            
        return response;
    }

    async getDadosTabela(empresaAtiva, quantidade, skip, ordenacao, raiz) {
    
        await checkSessao();
        let resource = "AnaliseClientes/" + empresaAtiva + "/Resumo/" + quantidade + "/" + skip + "/" + ordenacao + "/" + raiz;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result.mensagem);
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err.mensagem);
                return err.data;
            });

        return response;
    }
};