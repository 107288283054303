import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Theme/variable.css";
import "./Theme/theme.css";
import "./Theme/theme";
// import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";
import "alertifyjs/build/css/alertify.css";
import 'react-datepicker/dist/react-datepicker.css';
import LoadingPage from './Assets/PageLoading/PageLoading';
import { CookiesProvider } from "react-cookie";
//import swDev from './swDev'; 
import { AuthProvider } from './hooks/useAuth';
// const LandingPage = lazy(() => import('./Pages/LandingPage/Lp'));
const Login = lazy(() => import('./Pages/Login/Login'));
const App = lazy(() => import('./App'));

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <AuthProvider>
                <CookiesProvider>
                    <Suspense fallback={<div><LoadingPage size={15} color={"#59534C"} /></div>}>
                        <Switch>
                            <Route path='/' exact component={Login} />
                            <Route path='/Login' exact component={Login} />
                            <Route path='/Login/:redirectUrl' exact component={Login} />
                            {/* <Route path='/Forgot' exact component={Forgot} /> */}
                            <Route path='*' component={App} />
                        </Switch>
                    </Suspense>
                </CookiesProvider>
            </AuthProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// referencias serviceWorker
//https://create-react-app.dev/docs/making-a-progressive-web-app/
// https://dev.to/oieduardorabelo/pwa-criar-notificacao-de-uma-nova-versao-esta-disponivel-1945
// https://youtu.be/IaJqMcOMuDM

serviceWorker.register();
//swDev(); 