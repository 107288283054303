import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin(){
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao(){
    const currentUser = authenticationService.currentUserValue;
     if (!currentUser) {             

        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
        PushToLogin();
       
    }
     
    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if(expiration < date){
        setTimeout(() => {
            window.location.reload();
        }, 500);
         //não está logado, redirecione para a página de login com o url de retorno
        
         PushToLogin();
    }
}

export default class PerfilService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getPerfilUser(userId) {
        await checkSessao();
        let resource = "Account/" + userId;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                 
                return result.data;
            })
            .catch(function (err) {
                 
                return err.data;
            });

        return response;
    }

    async editPerfilUser(userId, data) {
      let resource = "Account/" + userId;
      let url = this.urlBase + resource;

      var response = await fetch(url, {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
      })
          .then(handleResponse)
          .then(function (result) {
               
              return result.data;
          })
          .catch(function (err) {
               
              return err.data;
          });

      return response;
    }

    async getPlanos() {
      let resource = "Planos";
      let url = this.urlBase + resource;

      var response = await fetch(url, {
          method: 'GET',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
      })
          .then(handleResponse)
          .then(function (result) {
               
              return result.data;
          })
          .catch(function (err) {
               
              return err.data;
          });

      return response;
    }

    async changePlanos(empresaId, planoId, usuarioId) {
      let resource = `Planos/Contratar/${usuarioId}/${empresaId}/${planoId}`;
      let url = this.urlBase + resource;

      var response = await fetch(url, {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
      })
          .then(handleResponse)
          .then(function (result) {
               
              return result.data;
          })
          .catch(function (err) {
               
              return err.data;
          });

      return response;
    }
    
};