import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import { DecodeTokenJwt } from '../Assets/Helpers';
import authHeader from './AuthHeaderService';
import dadosCards from '../Pages/Analises/Inteligencia/jsons/dadosCards.json';
import dadosGraficos1 from '../Pages/Analises/Inteligencia/jsons/dadosGraficos1.json';
import dadosGraficos2 from '../Pages/Analises/Inteligencia/jsons/dadosGraficos2.json';
import dadosTabela from '../Pages/Analises/Inteligencia/jsons/dadosTabela.json';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';
import moment from 'moment';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class InteligenciaService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async GetEstadosDest(empresasId) {
        await checkSessao();

        let resource = `InteligenciaComercial/estados/dest`;
        let url = this.urlBase + resource;
        
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetCidadesDest(empresasId, uf) {
        await checkSessao();

        let resource = `InteligenciaComercial/cidades/dest?uf=${encodeURI(uf)}`;
        let url = this.urlBase + resource;
        
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetEstadosRem(empresasId) {
        await checkSessao();

        let resource = `InteligenciaComercial/estados/rem`;
        let url = this.urlBase + resource;
        
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetCidadesRem(empresasId, uf) {
        await checkSessao();

        let resource = `InteligenciaComercial/cidades/rem?uf=${encodeURI(uf)}`;
        let url = this.urlBase + resource;
        
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetRemetentes(empresasId, search) {
        await checkSessao();

        let resource = `InteligenciaComercial/remetentes?search=${encodeURI(search)}`;
        let url = this.urlBase + resource;
        
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetClientes(empresasId, search) {
        await checkSessao();

        let resource = `InteligenciaComercial/clientes?search=${encodeURI(search)}`;
        let url = this.urlBase + resource;
        
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresasId),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetDadosGraficos(empresaId, filtros) {
        await checkSessao();
        let resource = `InteligenciaComercial/graficos/${empresaId}`;
        let url = this.urlBase + resource;
        
        let data = {
            periodoInicial: moment().add(-1, 'year').format('YYYY-MM-DDTHH:mm:ssZ'),
            periodoFinal: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
            origemUF: '',
            destinoUF: '',
            municipioOrigem: '',
            municipioDestino: '',
            remetente: [],
            cliente: [],
        };
        
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(filtros),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

    async GetDadosTabela(empresaId, filtros, ordenacao, quantidade, skip) {
        await checkSessao();
        let resource = `InteligenciaComercial/legenda/${empresaId}?ordenacao=${ordenacao}&quantidade=${quantidade}&skip=${skip}`;
        let url = this.urlBase + resource;
        
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(filtros),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {

                return result.data;
            })
            .catch(function (err) {

                return err.data;
            });

        return response;
    }

};